<template>
<div>
    <el-breadcrumb class="breadcrumb-title" separator="/">
            <el-breadcrumb-item>企业设置</el-breadcrumb-item>
            <el-breadcrumb-item>企业信息</el-breadcrumb-item>
        </el-breadcrumb>
  <el-card>
    <div slot="header" class="clearfix">
      <span>企业信息</span>
    </div>
    <div class="card-title">基本信息</div>
    <el-row>
      <el-col :span="8">
        <span class="grid-content">企业名称：</span>
        <span class="grid-content">{{info.company_name}}</span>
      </el-col>
      <el-col :span="8">
        <span class="grid-content">行业类型：</span>
        <span class="grid-content">{{info.industry_cn}}</span>
      </el-col>
      <el-col :span="8">
        <span class="grid-content">人员规模：</span>
        <span class="grid-content">{{info.company_scale_cn}}</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <span class="grid-content">企业编号：</span>
        <span class="grid-content">{{info.company_number}}</span>
      </el-col>
      <el-col :span="8">
        <span class="grid-content">所在地区：</span>
        <span class="grid-content">{{info.area}}</span>
      </el-col>
      <el-col :span="8">
        <span class="grid-content">创建时间：</span>
        <span class="grid-content">{{info.create_time}}</span>
      </el-col>
    </el-row>
  </el-card>
</div>
</template>
<script>
import request from "@request/api/index";
import moment from "dayjs";
export default {
  components: {},
  data() {
    return {
      info: {},
    };
  },

  created() {
    this.getDate();
  },
  methods: {
    async getDate() {
      let params = {
        // company_name: this.info.company_name,
        // company_number: this.info.company_number,
        // created_at: this.info.created_at,
        // company_scale_cn: this.info.company_scale_cn,
        // industry_cn: this.info.industry_cn,
        // area: this.info.area,
      };
      await request.enterprise.requestInfo({ params }).then((res) => {
        this.info = res.data.data;
				this.info.create_time = moment(this.info.create_time * 1000).format("YYYY-MM-DD")
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-card {
  border-radius: 8px;
}
.clearfix {
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
}
.card-title {
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  margin: 18px 0 18px 0;
}
.el-col {
  margin: 12px 0 12px 0;
}
.grid-content {
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}
</style>
